import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, ScrollView, Image } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { Card, Title, Paragraph, Button } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser';

const About1 = () => (

  <ScrollView>
    <PaperProvider>
    <Card>
        <Card.Content>
          <Card.Cover
            // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/phclearning2.png' }}
            source={require('../assets/images/phclearning2.png')}
          />
          <Paragraph></Paragraph>
          <Title>สสม.LEARNING</Title>
          <Paragraph>พัฒนาโดย </Paragraph>
          <Paragraph>ศูนย์พัฒนาการสาธารณสุขมูลฐาน ภาคตะวันออกเฉียงเหนือ จังหวัดขอนแก่น กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข</Paragraph>
          <Paragraph></Paragraph>
          <Title>LINE OFFICIAL ACCOUNT</Title>
          <Paragraph>LINE OA : สสม.LEARNING </Paragraph>
          <Paragraph>แอดไลน์เราไว้ไม่พลาดข่าวสาร</Paragraph>
          <Paragraph></Paragraph>
          <Button
            onPress={() => WebBrowser.openBrowserAsync('https://lin.ee/jtCs9Hz')}
            icon="comment-multiple-outline"
            mode="contained"
            color="slateblue"
            style={{margin:5 , marginBottom: 30 }}>
            แอดไลน์
          </Button>
          <Paragraph></Paragraph>
        </Card.Content>
        
      </Card>
    </PaperProvider>
  </ScrollView>

);

export default About1;
