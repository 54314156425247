import * as React from 'react';
import { BottomNavigation, Text, Appbar } from 'react-native-paper';
import { PaperProvider } from 'react-native-paper';
import Home1 from './Home1';
import About1 from './About1';
import Course1 from './Course1';

const HomeRoute = () => <Home1 />;;

const CourseRoute = () => <Course1 />;;

const AboutRoute = () => <About1 />;;

const Tab1 = () => {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'home', title: 'หน้าหลัก', focusedIcon: 'home'},
    { key: 'course', title: 'หลักสูตร', focusedIcon: 'book-open-page-variant' },
    { key: 'about', title: 'เกี่ยวกับ', focusedIcon: 'information-outline' },

  ]);

  const renderScene = BottomNavigation.SceneMap({
    home: HomeRoute,
    course: CourseRoute,
    about: AboutRoute,
  });

  return (
    <PaperProvider>
      <Appbar.Header  style={{backgroundColor:'ghostwhite'}}> 
          <Appbar.Content title='สสม.LEARNING' color='black'/>
      </Appbar.Header>
      <BottomNavigation
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        renderScene={renderScene}
        // barStyle={{ backgroundColor: 'slateblue' }}
        barStyle={{ backgroundColor: '#E0D2FC' }}
        
      />
    </PaperProvider>
  );
};

export default Tab1;