import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, ScrollView, Image } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { Card, Title, Paragraph, Button } from 'react-native-paper';
// import * as WebBrowser from 'expo-web-browser';

const Home1 = () => (

  <ScrollView>
    <PaperProvider>
      <Card>
        <Card.Content>
          <Card.Cover
            // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/app2.png' }}
            source={require('../assets/images/app2.png')}
          />
          <Paragraph></Paragraph>
          <Title>สสม.LEARNING : เรียนออนไลน์การสาธารณสุขมูลฐาน</Title>
          
          <Paragraph>
            ศูนย์พัฒนาการสาธารณสุขมูลฐาน ภาคตะวันออกเฉียงเหนือ จังหวัดขอนแก่น
            กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข
            เปิดพื้นที่แห่งการเรียนรู้การสาธารณสุขมูลฐานแบบออนไลน์   
            เป็นแหล่งรวบรวมหลักสูตรออนไลน์ สำหรับ เครือข่ายสาธารณสุขมูลฐาน 
            สุขภาพภาคประชาชน อสม. และผู้สนใจทั่วไป
          </Paragraph>
          <Paragraph></Paragraph>
          <Title>หลักสูตรที่เปิดสอน</Title>
          <Paragraph>
            สามารถกลับมาทบทวนบทเรียนได้ตลอดเวลา
            เรียนจบแล้วได้รับประกาศนียบัตร           
          </Paragraph>
          <Paragraph></Paragraph>
          <Paragraph></Paragraph>
        </Card.Content>
      </Card>     
    </PaperProvider>
  </ScrollView>

);

export default Home1;
