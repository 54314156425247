import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, ScrollView, Image } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { Card, Title, Paragraph, Button } from 'react-native-paper';
import Tab1 from './components/Tab1';


const App = () => (

    <PaperProvider>
      <Tab1 />
    </PaperProvider>
);

export default App;
