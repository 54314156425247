import { StatusBar } from 'expo-status-bar';
import { StyleSheet,Portal, Text, View, ScrollView, Image, Alert } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { Card, Title, Paragraph, Button, Divider } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser';

const Course1 = () => (

  <ScrollView>
    <PaperProvider>
        <Card>
          <Card.Content>
            <Card.Cover
              // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/app3.png' }}
              source={require('../assets/images/app3.png')}
            />
            <Title>หลักสูตร อสม. นวัตกรสังคม (2566)</Title>
            <Paragraph>ผู้พัฒนา : กองสนับสนุนสุขภาพภาคประชาชน กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข</Paragraph>
            <Paragraph>สถานะ : เปิดรับสมัคร 1 มิถุนายน 2566</Paragraph>
            <Paragraph></Paragraph> 
            <Button
              onPress={() => WebBrowser.openBrowserAsync('https://drive.google.com/file/d/1xwgUOTx_uY7txOVAym-ZZH_1LbEw0zwq/view')}
              icon="book-open-page-variant"
              mode="outlined"
              color="slateblue"
              style={{margin:5 , marginBottom: 30 }}>
              โครงสร้างหลักสูตร
            </Button>
            <Button
              onPress={() => WebBrowser.openBrowserAsync('https://lin.ee/JM0adVT')}
              icon="book-open-page-variant"
              mode="contained"
              color="slateblue"
              style={{margin:5 , marginBottom: 30 }}>
              เข้าเรียน
            </Button>           
          </Card.Content>
          <Card.Actions>
            
          </Card.Actions>
        </Card>


        <Card>
          <Card.Content>
            <Paragraph></Paragraph>
            <Card.Cover
              // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/app4.png' }}
              source={require('../assets/images/app4.png')}
            />
            <Title>หลักสูตร อสม.นวัตกรสังคม สู้ภัยโควิด 19</Title>
            <Paragraph>ผู้พัฒนา : ศูนย์พัฒนาการสาธารณสุขมูลฐาน ภาคตะวันออกเฉียงเหนือ จังหวัดขอนแก่น กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข</Paragraph>
            <Paragraph>สถานะ : ปิดรับสมัคร</Paragraph>
            <Paragraph></Paragraph>
            <Button
              onPress={() => WebBrowser.openBrowserAsync('https://drive.google.com/file/d/1XNlJi5YLdbs1rqaKCl2QyeoGiztoOSOT/view')}
              icon="book-open-page-variant"
              mode="outlined"
              color="slateblue"
              style={{margin:5 , marginBottom: 30 }}>
              โครงสร้างหลักสูตร
            </Button>     
            
            <Button 
            color="slateblue" 
            icon="book-open-page-variant"   
            mode="contained" 
            style={{ marginBottom: 30 }} 
            onPress={() => Alert.alert('ขออภัย หลักสูตรดังกล่าวปิดรับสมัครแล้ว')}>
            เข้าเรียน
            </Button>
            <Paragraph></Paragraph>
          </Card.Content>
          <Card.Actions>        
          </Card.Actions>        
        </Card>


        <Card>
          <Card.Content>
            <Paragraph></Paragraph>
            <Card.Cover
              // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/app2.png' }}
              source={require('../assets/images/app2.png')}
            />
            <Title>หลักสูตร การสาธารณสุขมูลฐาน</Title>
            <Paragraph>หลักการ แนวคิด วิวัฒนาการ การสาธารณสุขมูลฐาน (Primary Health Care)</Paragraph>
            <Paragraph>ผู้พัฒนา : ศูนย์พัฒนาการสาธารณสุขมูลฐาน ภาคตะวันออกเฉียงเหนือ จังหวัดขอนแก่น กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข</Paragraph>
            <Paragraph>สถานะ : ปิดรับสมัคร</Paragraph>
            <Paragraph> </Paragraph> 

            <Button 
            color="slateblue" 
            icon="book-open-page-variant"   
            mode="contained" 
            style={{ marginBottom: 30 }} 
            onPress={() => Alert.alert('ขออภัย หลักสูตรดังกล่าวปิดรับสมัครแล้ว')}>
            เข้าเรียน
            </Button>
      
          </Card.Content>                
        </Card>


        <Card>
          <Card.Content>
            <Paragraph></Paragraph>
            <Card.Cover
              // source={{ uri: 'https://lms.ulitestudio.com/expo_app/images/app3.png' }}
              source={require('../assets/images/app3.png')}
              // source={require('./app2.png')}
            />
            <Title>องค์กร อสม. สร้างสุขภาพ</Title>
            <Paragraph>ผู้พัฒนา : ศูนย์พัฒนาการสาธารณสุขมูลฐาน ภาคตะวันออกเฉียงเหนือ จังหวัดขอนแก่น กรมสนับสนุนบริการสุขภาพ กระทรวงสาธารณสุข</Paragraph>
            <Paragraph>สถานะ : เปิดรับสมัคร</Paragraph>
            <Paragraph></Paragraph>

            <Button
              onPress={() => WebBrowser.openBrowserAsync('https://webapp1.suuny-ssmssm.repl.co')}
              icon="book-open-page-variant"
              mode="contained"
              color="slateblue"
              style={{margin:5 , marginBottom: 30 }}>
              เข้าเรียน
            </Button>


          </Card.Content>
          <Card.Actions>
          </Card.Actions>
      </Card>

    </PaperProvider>
  </ScrollView>

);

export default Course1;
